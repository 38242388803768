import React from 'react';
import type { WCmsEventView } from '@zola/svc-web-api-ts-client';

import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';

import { StyledAddress } from '../Schedule.styles';

const ScheduleAddress = ({ event }: { event: WCmsEventView }): JSX.Element => {
  const {
    state: {
      components: { CmsEntityBodyLink },
    },
  } = useWebsiteThemeContext();

  const baseRender = () => (
    <StyledAddress
      singleLine
      addressData={{
        address1: event.address_1,
        address2: event.address_2,
        city: event.city,
        state: event.state_province,
        zip: event.postal_code,
        countryCode: event.country_code,
      }}
    />
  );

  if (event.google_map_url) {
    return (
      <CmsEntityBodyLink href={event.google_map_url} target="_blank" rel="noopener noreferrer">
        {baseRender()}
      </CmsEntityBodyLink>
    );
  }
  return baseRender();
};

export default ScheduleAddress;
