import React from 'react';

import type { CommonIconProps } from '~/components/publicWebsiteV2/pages/Schedule/Schedule';

const WelcomeEventIcon: React.FC<CommonIconProps> = ({ strokeProps, style }) => (
  <svg viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" style={style}>
    <g
      id="0_welcome-event-compound-path"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(15.000000, 20.000000)">
        <ellipse id="Oval" cx="33.072" cy="4.86" rx="3.41333333" ry="3.456" />
        <path
          d="M39.7386667,15.0606 C39.7070064,12.0187769 37.6062936,9.40148228 34.672,8.748 C36.5569824,7.96549194 37.6189833,5.92952036 37.1945186,3.9120569 C36.7700539,1.89459345 34.9807279,0.473618863 32.9444207,0.5368859 C30.9081134,0.600152937 29.2082042,2.1295363 28.9067099,4.16955493 C28.6052156,6.20957356 29.7885111,8.17578492 31.7173333,8.8398 C30.2052697,9.24670149 28.8971152,10.2092788 28.048,11.5398 C27.3910195,12.5414367 27.0420939,13.717808 27.0453333,14.9202 C26.8693333,19.1862 28.048,23.6412 28.5653333,25.353 L28.816,26.082 L29.3493333,25.866 C30.6767731,25.2462581 31.9552047,24.5241314 33.1733333,23.706 C34.403801,24.6556464 35.7177566,25.4889416 37.0986667,26.1954 L37.632,26.5194 L37.8986667,25.8444 L37.8986667,25.8174 C37.8986667,25.7256 37.9786667,25.5474 38.032,25.3638 C38.464,23.841 39.824,19.332 39.7386667,15.0606 Z M29.392,4.8006 C29.392,2.74278702 31.0395921,1.0746 33.072,1.0746 C35.1044079,1.0746 36.752,2.74278702 36.752,4.8006 C36.752,6.85841298 35.1044079,8.5266 33.072,8.5266 C31.0428868,8.5236354 29.3978709,6.86047067 29.392,4.806 L29.392,4.8006 Z M37.5093333,25.164 C37.4293333,25.4232 37.3813333,25.5798 37.36,25.704 L37.36,25.704 C35.9455558,24.9801451 34.6025772,24.1212505 33.3493333,23.139 L33.2,23.0202 L33.04,23.1282 C31.8008561,23.978145 30.4972323,24.7274834 29.1413333,25.3692 L29.1413333,25.3692 L29.104,25.245 C28.5706667,23.5548 27.4346667,19.1754 27.6053333,14.985 C27.5853166,13.879109 27.8917256,12.7923318 28.4853333,11.8638 C29.3086591,10.5294557 30.6372666,9.59620052 32.16,9.2826 C33.8807008,8.92525562 35.6690104,9.36735467 37.0334406,10.4873933 C38.3978708,11.607432 39.1948471,13.2875522 39.2053333,15.066 L39.2053333,15.066 C39.2906667,19.2564 37.9466667,23.7006 37.5093333,25.164 Z"
          id="Shape"
          fill="currentColor"
          fillRule="nonzero"
          {...strokeProps}
        />
        <path
          d="M13.12,13.662 C12.612925,11.1856193 10.7018081,9.25061344 8.256,8.7372 C10.1308974,7.93936126 11.1767072,5.90070423 10.7431477,3.88886069 C10.3095881,1.87701716 8.52009158,0.464749848 6.48736248,0.530203007 C4.45463339,0.595656165 2.75738048,2.12019558 2.45095133,4.15586649 C2.14452219,6.19153739 3.3161558,8.15879353 5.23733333,8.8344 C2.36560895,9.61482247 0.411734694,12.3063839 0.533333333,15.3144 C0.582002708,18.7211315 1.09529997,22.1047206 2.05866667,25.3692 L2.30933333,26.082 L2.84266667,25.866 C4.16996367,25.2459739 5.44837421,24.5238592 6.66666667,23.706 C7.89710007,24.6556965 9.21106043,25.4889946 10.592,26.1954 L11.1253333,26.5194 L11.4186667,25.758 C12.16,23.5656 13.392,19.278 13.3066667,15.12 C13.3021913,14.628195 13.2395223,14.1387051 13.12,13.662 L13.12,13.662 Z M2.93866667,4.806 C2.93866667,2.74818702 4.58625879,1.08 6.61866667,1.08 C8.65107455,1.08 10.2986667,2.74818702 10.2986667,4.806 C10.2986667,6.86381298 8.65107455,8.532 6.61866667,8.532 C4.58747673,8.52902591 2.94160404,6.86257981 2.93866667,4.806 L2.93866667,4.806 Z M10.8853333,25.5852 L10.848,25.6878 L10.848,25.6878 C9.43358784,24.9638879 8.09061451,24.1049966 6.83733333,23.1228 L6.688,23.004 L6.528,23.112 C5.28878552,23.96183 3.98517093,24.7111631 2.62933333,25.353 L2.62933333,25.353 L2.592,25.218 C1.63872655,22.0067947 1.12724477,18.6778559 1.072,15.3252 C1.01319374,14.1027094 1.33398327,12.8922651 1.98933333,11.8638 C2.81159118,10.5274081 4.14035234,9.59209233 5.664,9.2772 C7.17394292,8.92489255 8.76039751,9.20340806 10.0650554,10.0498425 C11.3697132,10.8962769 12.282527,12.2392338 12.5973333,13.7754 C12.7017685,14.2139003 12.7536975,14.6635338 12.752,15.1146 L12.752,15.1146 C12.8373333,19.1862 11.6213333,23.4198 10.8853333,25.5852 Z"
          id="Shape"
          fill="currentColor"
          fillRule="nonzero"
          {...strokeProps}
        />
        <path
          d="M23.04,9.7308 C22.061151,9.51303793 21.0369839,9.72278405 20.2186667,10.3086 C19.0110791,9.45391133 17.4099127,9.43458248 16.1825425,10.259877 C14.9551723,11.0851715 14.3516951,12.5869223 14.6613333,14.0454 C14.896,15.2496 16.88,17.7606 19.9946667,19.332 L20.2346667,19.4508 L20.4746667,19.332 C23.5786667,17.7606 25.5573333,15.255 25.808,14.0562 C26.0109796,13.115981 25.8367559,12.1326296 25.3236563,11.3224723 C24.8105567,10.5123151 24.0006116,9.94171577 23.072,9.7362 L23.04,9.7308 Z M25.2586667,13.9428 C25.072,14.8824 23.232,17.3178 20.224,18.846 L20.224,18.846 C17.2106667,17.3232 15.3706667,14.877 15.184,13.932 C14.9101653,12.6569645 15.4571921,11.3452391 16.5506989,10.6547686 C17.6442056,9.96429803 19.0482842,10.0440412 20.0586667,10.854 L20.224,10.9836 L20.3893333,10.854 C21.4014108,10.0452056 22.8064021,9.96796591 23.8990199,10.6610542 C24.9916376,11.3541424 25.535929,12.6678849 25.2586667,13.9428 L25.2586667,13.9428 Z"
          id="Shape"
          fill="currentColor"
          fillRule="nonzero"
          {...strokeProps}
        />
      </g>
    </g>
  </svg>
);
export default WelcomeEventIcon;
